














import {Vue, Component, Prop} from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import { Route } from 'vue-router';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';

const THUMB_SIZE: number = 400;

@Component({
  components: {
    ImgViewer,
  },
})
export default class ProductItemImage extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;

  /**
   * Getters
   */
  private get thumbPath(): string {
    return this.product.getThumb(THUMB_SIZE);
  }

  /**
   * Handlers
   */
  private onLinkClick() {
    this.$emit('detailsClick');
  }
}
